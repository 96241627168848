export enum ProviderSource {
  apple = "apple",
  cronometer = "cronometer",
  dexcom = "dexcom",
  fitbit = "fitbit",
  garmin = "garmin",
  google = "google",
  oura = "oura",
  tenovi = "tenovi",
  whoop = "whoop",
  withings = "withings",
}
